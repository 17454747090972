@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rakkas&display=swap");

@font-face {
  font-family: "Hauora";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/hauora/Hauora-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Hauora";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/hauora/Hauora-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Hauora";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fonts/hauora/Hauora-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Hauora";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("assets/fonts/hauora/Hauora-ExtraBold.otf") format("opentype");
}

.container {
  @apply w-full lg:w-[85%] px-5 lg:px-0 m-auto animate-fade-in;
}

::-webkit-scrollbar {
  /* @apply lg:w-1 lg:h-1 lg:bg-transparent; */
  @apply w-0 h-0 bg-transparent;
}

/* Track */
::-webkit-scrollbar-track {
  @apply lg:bg-transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply lg:bg-gray-400 lg:rounded-xl;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply lg:bg-gray-500;
}

.scrollbar-hide {
  /* scrollbar-width: none;
  -ms-overflow-style: none; */
  overflow-y: hidden;
}

/* .scrollbar-hide::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
} */

.rekursiv-grayscale {
  filter: invert(71%) sepia(0%) saturate(26%) hue-rotate(187deg) brightness(94%) contrast(83%);
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: "Hauora", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
